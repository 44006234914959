import React from "react";
import { Grid, TextField, InputAdornment, Typography } from "@mui/material";
import InstagramIcon from '@mui/icons-material/Instagram';
import LanguageIcon from '@mui/icons-material/Language';
import LinkIcon from '@mui/icons-material/Link';
import TwitterIcon from '@mui/icons-material/Twitter';
import { Facebook, MusicNote } from "@mui/icons-material";
const SocialMediaComponent = ({ socialMedia, handleChange }) => {
  return (
    <Grid container spacing={2} sx={{ mb: 2, mt: 2 }}>
      <Grid item xs={12}>
        <Typography variant="h6">Social Media</Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Instagram"
          name="instagram"
          value={socialMedia.instagram || ""}
          onChange={handleChange}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <InstagramIcon />
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          label="Twitter"
          name="twitter"
          value={socialMedia.twitter || ""}
          onChange={handleChange}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <TwitterIcon />
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          label="Website"
          name="website"
          value={socialMedia.website || ""}
          onChange={handleChange}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LanguageIcon />
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          label="Other Links"
          name="other"
          value={socialMedia.other || ""}
          onChange={handleChange}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LinkIcon />
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          label="Facebook"
          name="facebook"
          value={socialMedia.facebook || ""}
          onChange={handleChange}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Facebook />
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          label="Sound Cloud"
          name="soundCloud"
          value={socialMedia.soundCloud || ""}
          onChange={handleChange}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MusicNote />
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          label="Spotify"
          name="spotify"
          value={socialMedia.spotify || ""}
          onChange={handleChange}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MusicNote />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
};

export default SocialMediaComponent;
