import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import AuthForm from '../../../layout/main/components/authentication/auth.form';
import VerifyForm from '../../../layout/main/components/authentication/verify.form';
import { CircularProgress } from '@mui/material';

export default function OnboardingOrganizer() {
  const { userEmail, isAuthenticated, isInitialized, user } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (isAuthenticated && user?.name && user?.dob && user?.gender) {
      navigate('/onboarding/organizer/portfolio');
    } else if (isAuthenticated) {
      navigate('/onboarding');
    }
    if (isInitialized) {
      setLoading(false);
    }
  }, [isInitialized, navigate, isAuthenticated, user]);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
      {loading && <CircularProgress />}
      {!loading && (
        <>
          <div>
            {!userEmail && !isAuthenticated && <AuthForm type="organizer" hideSocials={true} setLoading={setLoading} isLoading={loading} title="Enter your email address to begin!" subtitle="We'll send you a verification code to confirm your email address." />}
            {userEmail && !isAuthenticated && <VerifyForm type="organizer" userEmail={userEmail} setLoading={setLoading} isLoading={loading} title="Enter the 4-digit code we sent you" subtitle="We've sent you a 4-digit code to confirm your email address." />}
          </div>
        </>
      )}
    </div>
  )
}
