import React, { useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Container, TextField, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import useAuth from '../../../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

export default function ProfileForm({ setLoading, redirectTo, user }) {
  const { updateProfile } = useAuth();
  const navigate = useNavigate();
  const [name, setName] = useState(user?.name || '');
  const [dob, setDob] = useState(user?.dob ? dayjs(user.dob) : null);
  const [gender, setGender] = useState(user?.gender || '');
  const [errors, setErrors] = useState({});
  const [apiError, setApiError] = useState('');

  const validate = () => {
    let tempErrors = {};
    tempErrors.name = name ? "" : "Name is required.";
    tempErrors.dob = dob ? "" : "Date of Birth is required.";
    tempErrors.gender = gender ? "" : "Gender is required.";
    setErrors(tempErrors);
    return Object.values(tempErrors).every(x => x === "");
  };

  const handleSave = async () => {
    if (!validate()) return;
    try {
      setLoading(true);
      setApiError('');
      await updateProfile({ name, dob, gender, email: user.email });
      navigate(redirectTo);
    } catch (error) {
      console.error(error);
      setApiError('Failed to update profile. Please try again.');
    } finally {
      setLoading(false);
    }
  }

  const handleChange = (setter) => (event) => {
    setter(event.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, [event.target.name]: '' }));
    setApiError('');
  };

  return (
    <Container maxWidth="sm">
      <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
        <TextField
          label="Name"
          fullWidth
          margin="normal"
          value={name}
          onChange={handleChange(setName)}
          error={!!errors.name}
          helperText={errors.name}
          name="name"
        />
      </FormControl>

      <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
        <LocalizationProvider dateAdapter={AdapterDayjs} >
          <DatePicker format="DD/MM/YYYY" label="Date of Birth" fullWidth value={dob} onChange={(value) => { setDob(value); setErrors((prevErrors) => ({ ...prevErrors, dob: '' })); setApiError(''); }} />
        </LocalizationProvider>
        {errors.dob && <p style={{ color: 'red' }}>{errors.dob}</p>}
      </FormControl>

      <FormControl fullWidth sx={{ mt: 2 }}>
        <InputLabel id="artist-gender">Gender</InputLabel>
        <Select
          labelId="artist-gender"
          id="demo-simple-select"
          value={gender}
          label="Gender"
          onChange={handleChange(setGender)}
          error={!!errors.gender}
          name="gender"
        >
          <MenuItem value="male">Male</MenuItem>
          <MenuItem value="female">Female</MenuItem>
          <MenuItem value="other">Prefer not to say</MenuItem>
        </Select>
        {errors.gender && <p style={{ color: 'red' }}>{errors.gender}</p>}
      </FormControl>

      <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
        <TextField
          label="Email"
          fullWidth
          margin="normal"
          value={user.email}
          disabled
        />
      </FormControl>

      {apiError && <p style={{ color: 'red' }}>{apiError}</p>}

      <Button variant="contained" color="primary" onClick={handleSave} sx={{ mt: 3 }} fullWidth>
        Save
      </Button>
    </Container>
  );
}
