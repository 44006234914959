import { createTheme } from '@mui/material/styles';

const darkTheme = createTheme({
	palette: {
		mode: 'dark',
		primary: {
			main: '#D328A7',
		},
		secondary: {
			main: '#D328A7',
		},
		text: {
			primary: '#ffffff',
			secondary: '#bdbdbd',
		},
	},
	typography: {
		fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
		h4: {
			fontWeight: 700,
			fontSize: '2rem',
			color: '#ffffff',
		},
		h6: {
			fontWeight: 600,
			color: '#ffffff',
		},
		body1: {
			fontSize: '1rem',
			color: '#bdbdbd',
		},
		button: {
			textTransform: 'none',
		},
	},
	components: {
		MuiAppBar: {
			styleOverrides: {
				root: {
					backgroundColor: 'trasnparent',
					boxShadow: 'none',
					backdropFilter: 'blur(20px)',
				},
			},
		},
		
	},
});

const lightTheme = createTheme({
	palette: {
		mode: 'light',
		primary: {
			main: '#D328A7',
		},
		secondary: {
			main: '#D328A7',
		},
	},
	typography: {
		fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
		h4: {
			fontWeight: 700,
			fontSize: '2rem',
			color: '#ffffff',
		},
		h6: {
			fontWeight: 600,
			color: '#ffffff',
		},
		body1: {
			fontSize: '1rem',
			color: '#bdbdbd',
		},
		button: {
			textTransform: 'none',
		},
	},
	
});

export { darkTheme, lightTheme };
