
import { useNavigate } from 'react-router-dom';
import {
  Grid,
  Box,
  Typography,
  IconButton,
  Container,
} from '@mui/material';
import React from 'react';
import { MusicNote, Event } from '@mui/icons-material';
import useAuth from '../../hooks/useAuth';



export default function Onboarding() {
  const navigate = useNavigate();

  const { user } = useAuth();

  const handleNavigate = (path) => {
    navigate(path);
  };
  return (
    <div>

      <Container maxWidth="lg" style={{ marginTop: '5rem', marginBottom: '10rem' }} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '20vh' }}>
        <Typography variant="h4">Welcome to the Xclusive!!</Typography>
        <Typography variant="subtitle1">Please select the type of account you are creating</Typography>
        <Grid container spacing={4} justifyContent="space-between" sx={{ marginTop: '2rem' }}>
          {user.type === 'artist' && (
            <Grid item xs={12} sm={6} md={5}>
              <Box
              sx={{
                borderRadius: '16px',
                height: '250px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                cursor: 'pointer',
                transition: 'transform 0.3s, box-shadow 0.3s',
                '&:hover': {
                  transform: 'scale(1.05)',
                  boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
                },
              }}
              onClick={() => handleNavigate('/onboarding/artist/portfolio')}
            >
              <IconButton size="large" fontSize="large">
                <MusicNote sx={{ fontSize: '10rem' }} color="primary" />
              </IconButton>
              <Typography variant="h6" align="center" style={{ marginTop: '1rem' }}>
                Artist
              </Typography>
              </Box>
            </Grid>
          )}

          {/* Organizer Card */}
          {user.type === 'organizer' && (
            <Grid item xs={12} sm={6} md={5}>
              <Box
                sx={{
                borderRadius: '16px',
                height: '250px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                cursor: 'pointer',
                transition: 'transform 0.3s, box-shadow 0.3s',
                '&:hover': {
                  transform: 'scale(1.05)',
                  boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
                },
              }}
              onClick={() => handleNavigate('/onboarding/organizer/portfolio')}
            >
              <IconButton sx={{ fontSize: '10rem' }} size="large">
                <Event fontSize="inherit" color="secondary" />
              </IconButton>
              <Typography variant="h6" align="center" style={{ marginTop: '1rem' }}>
                Organizer
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      </Container>
    </div>
  )
}