import React, { useState } from "react";
import { Grid, Avatar, CircularProgress, IconButton, Typography } from "@mui/material";
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { UploadService } from "../../../../../../services/upload.service";

const AvatarUpload = ({ onUploadComplete, avatar }) => {
  const [uploading, setUploading] = useState(false);
  const [avatarPreview, setAvatarPreview] = useState(null);
  const [error, setError] = useState("");

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 2 * 1024 * 1024) { // Check if file size is greater than 2MB
        setError("Your avatar size should be less than 2MB");
        return;
      }

      setUploading(true);
      setAvatarPreview(URL.createObjectURL(file));
      setError(""); // Clear any previous error

      const formData = new FormData();
      formData.append("file", file);

      try {
        const config = {
          method: 'post',
          maxBodyLength: Infinity,
          headers: {
            'origin': 'https://universe.einfobytes.com'
          },
          data: formData
        };
        const response = await UploadService.upload(config);
        onUploadComplete(response.url);
      } catch (error) {
        console.error("Error uploading file:", error);
      } finally {
        setUploading(false);
      }
    }
  };

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ mb: 2, mt: 2 }}>
      <Grid item>
        <label htmlFor="avatar-upload" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <input
            accept="image/*"
            id="avatar-upload"
            type="file"
            hidden
            onChange={handleFileChange}
          />
          <IconButton component="span">
            <div style={{ position: "relative", display: "inline-block" }}>
              <Avatar
                src={avatarPreview || avatar || ""}
                alt="Avatar Preview"
                sx={{ width: 100, height: 100 }}
              >
                {!avatarPreview && <PhotoCameraIcon fontSize="large" />}
              </Avatar>
              {uploading && (
                <CircularProgress
                  size={100}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    zIndex: 1,
                    backgroundColor: "rgba(255, 255, 255, 0.5)",
                    borderRadius: "50%",
                  }}
                />
              )}
            </div>
          </IconButton>
        </label>
        {error && <Typography color="error">{error}</Typography>}
      </Grid>
    </Grid>
  );
};

export default AvatarUpload;