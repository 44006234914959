import React, { useState } from "react";
import {
  Grid,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Avatar,
  CircularProgress,
  Box,
  Typography,
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { UploadService } from "../../../../../../services/upload.service";

const ImageUploadGrid = ({ images, setImages }) => {
  const [uploading, setUploading] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setUploading(true);
      const formData = new FormData();
      formData.append("file", file);

      try {
        const config = {
          method: 'post',
          maxBodyLength: Infinity,
          data: formData
        };
        const response = await UploadService.upload(config);
        setImages((prevImages) => [...prevImages, response.url]);
        console.log(images);
      } catch (error) {
        console.error("Error uploading file:", error);
      } finally {
        setUploading(false);
      }
    }
  };

  const handleDeleteClick = (index) => {
    setDeleteIndex(index);
    setOpenConfirm(true);
  };

  const handleConfirmDelete = () => {
    setImages((prevImages) => prevImages.filter((_, idx) => idx !== deleteIndex));
    setOpenConfirm(false);
    setDeleteIndex(null);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
    setDeleteIndex(null);
  };

  return (
    <Box
      sx={{

        borderRadius: '10px',
      }}
    >
      <Grid container justifyContent="space-between" alignItems="center" sx={{ marginBottom: 2 }}>
        <Grid item>
          <Typography variant="h6">Gallery</Typography>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            component="label"
            startIcon={<AddPhotoAlternateIcon />}
            disabled={uploading}
          >
            {uploading ? <CircularProgress size={24} sx={{ color: '#fff' }} /> : 'Add Image'}
            <input
              type="file"
              hidden
              accept="image/*"
              onChange={handleFileChange}
            />
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        {images.map((image, index) => (
          <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
            <Box sx={{ position: 'relative', overflow: 'hidden', borderRadius: '10px' }}>
              <Avatar
                src={image}
                alt={`Image ${index}`}
                variant="rounded"
                sx={{
                  width: '100%',
                  height: 'auto',
                  borderRadius: '10px',
                }}
              />
              <IconButton
                sx={{
                  position: 'absolute',
                  top: '5px',
                  right: '5px',
                  backgroundColor: 'rgba(255, 255, 255, 0.7)',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 0, 0, 0.7)',
                  },
                }}
                onClick={() => handleDeleteClick(index)}
              >
                <DeleteIcon color="error" />
              </IconButton>
            </Box>
          </Grid>
        ))}
        {images.length === 0 && (
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
            <Typography variant="body1" color="textSecondary" align="center">
              No images added yet.
            </Typography>
          </Grid>
        )}
      </Grid>

      {/* Confirmation dialog for deletion */}
      <Dialog open={openConfirm} onClose={handleCloseConfirm}>
        <DialogTitle>Delete Image</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this image? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirm} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ImageUploadGrid;
