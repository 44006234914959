import React from 'react';
import { Outlet } from 'react-router-dom';
import { Container } from '@mui/material';
import Header from './components/header';

const OnboardingLayout = () => {
  return (
    <div >
      <Header />
      <main style={{ marginTop: '100px' }}>
        <Container>
          <Outlet />
        </Container>
      </main>
      {/* <footer>
        <Container>
          <Footer />
        </Container>
      </footer> */}
    </div>
  );
};

export default OnboardingLayout;