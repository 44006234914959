
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import React, { useState, useCallback, useEffect } from "react";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Card,
  Box,
} from "@mui/material";
import { OrganizerService } from "../../../../../services/organizer.service";
import AvatarUpload from "./components/avatar-upload";
import ImageUploadGrid from "./components/image-upload.grid";
import VenueComponent from "./components/venue-component";

// Custom styles
const styles = {
  header: {
    textAlign: "center",
    padding: "20px 0",
  },
  avatarWrapper: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px",
    position: "relative",
  },
  avatarHover: {
    "&:hover": {
      cursor: "pointer",
      transform: "scale(1.1)",
      transition: "transform 0.2s ease-in-out",
    },
  },
  formWrapper: {
    padding: "20px",
    backgroundColor: "#2a2a2a",
    borderRadius: "10px",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
  },
  formFields: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  submitButton: {
    backgroundColor: "#3f51b5",
    color: "#fff",
    borderRadius: "25px",
    padding: "12px 0",
    "&:hover": {
      backgroundColor: "#283593",
    },
  },
  card: {
    backgroundColor: "#1b1b1b",
    padding: "30px",
    borderRadius: "15px",
  },
};


export default function OnboardingOrganizerPortfolioForm({ initialValues, setLoading }) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [images, setImages] = useState([]);
  const [fields, setFields] = useState(initialValues.venues ?? []);
  const [formData, setFormData] = useState({
    name: "",
    alias: "",
    bio: "",
    avatar: "",
    venues: [],
  });
  useEffect(() => {
    if (initialValues) {
      setFormData(initialValues);
      setFields(initialValues.venues ?? []);
      if (initialValues.images && images.length === 0) {
        initialValues.images.forEach((image) => {
          setImages((prevImages) => [...prevImages, image.imageId]);
        });
      }
    }
  }, [images.length, initialValues]);

  const [errors, setErrors] = useState({});

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }, []);

  const handleAvatarUpload = useCallback((uploadedUrl) => {
    setFormData((prevData) => ({
      ...prevData,
      avatar: uploadedUrl,
    }));
  }, []);

  const validate = useCallback(() => {
    let tempErrors = {};
    if (!formData.name) tempErrors.name = "Name is required";
    if (!formData.bio) tempErrors.bio = "Bio is required";
    // if (!formData.images || formData.images.length === 0) tempErrors.images = "At least one image is required";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  }, [formData]);

  const createOrganizer = async () => {
    try {
      await OrganizerService.createOrganizer({ ...formData, images, venues: fields });
      enqueueSnackbar("Organizer created successfully", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("Failed to create organizer", { variant: "error" });
    }
  }

  const updateOrganizer = async () => {
    try {
      await OrganizerService.updateOrganizer({ ...formData, images, venues: fields });
      enqueueSnackbar("Organizer updated successfully", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("Failed to update organizer", { variant: "error" });
    }
  }

  const handleSubmit = async (e) => {
    try {
      setLoading(true);
      e.preventDefault();
      if (validate()) {
        if (initialValues.name) {
          await updateOrganizer();
        } else {
          await createOrganizer();
        }
        navigate('/onboarding');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Box sx={{ paddingTop: "20px", paddingBottom: "20px" }}>

      <Card sx={styles.card}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={4}>
            <Grid item xs={12} sx={styles.avatarWrapper}>
              <Box sx={styles.avatarHover}>
                <AvatarUpload onUploadComplete={handleAvatarUpload} avatar={formData.avatar} />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6" color="white">Organizer Profile</Typography>
            </Grid>

            {[
              { label: "Name", name: "name", required: true, error: errors.name, helperText: errors.name },
            ].map((field, index) => (
              <Grid item xs={12} sm={12} key={index}>
                <TextField
                  label={field.label}
                  name={field.name}
                  value={formData[field.name] || ""}
                  onChange={handleChange}
                  fullWidth
                  required={field.required}
                  error={!!field.error}
                  helperText={field.helperText}
                  sx={{ borderRadius: "8px", backgroundColor: "#2a2a2a" }}
                  InputLabelProps={{ style: { color: "rgba(255, 255, 255, 0.7)" } }}
                />
              </Grid>
            ))}

            <Grid item xs={12}>
              <TextField
                label="Bio"
                name="bio"
                value={formData.bio}
                onChange={handleChange}
                fullWidth
                required
                multiline
                rows={4}
                error={!!errors.bio}
                helperText={errors.bio}
                sx={{ borderRadius: "8px", backgroundColor: "#2a2a2a" }}
                InputLabelProps={{ style: { color: "rgba(255, 255, 255, 0.7)" } }}
              />
            </Grid>

            <Grid item xs={12}>
              <ImageUploadGrid images={images} setImages={setImages} />
            </Grid>

            <Grid item xs={12}>
              <VenueComponent formFields={fields} setFieldsInForm={setFields} />
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" type="submit" fullWidth >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Card>
    </Box>
  )
}