
import React from 'react';
import { Box, Typography, Button, Link, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { styled } from '@mui/material/styles';
import OTPInput from 'react-otp-input';
import useAuth from '../../../../hooks/useAuth';

// Custom button style for the "Continue" button
const ContinueButton = styled(Button)(({ theme }) => ({
  borderRadius: '8px',
  backgroundColor: theme.palette.primary.main,
  color: '#fff',
  padding: '12px',
  textTransform: 'none',
  fontSize: '16px',
  fontWeight: 'bold',
}));

const VerifyForm = ({ handleClose, userEmail, setLoading, isLoading, title, subtitle, type }) => {
  const [otp, setOtp] = React.useState('');

  const [timer, setTimer] = React.useState(15);

  const { verify, login, resetEmail  } = useAuth();

  const handleSubmit = async () => {
    try {
      if (otp && otp.length === 4) {
        setLoading(true);
        await verify(userEmail, otp, type);
        if (handleClose) handleClose();
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }

  React.useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [timer]);

  const handleResend = async () => {
    try {
      setLoading(true);
      await login(userEmail, type);
      setTimer(60);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const handleBack = () => {
    resetEmail();
    if (handleClose) handleClose();
  }

  return (
    <>
      <Box sx={{ width: '100%', contentAlign: 'center' }}>
        {title && <Typography variant="h4" gutterBottom>{title}</Typography>}
        {subtitle && <Typography variant="body1" gutterBottom>{subtitle}</Typography>}
        {!(title && subtitle) && (
          <>
            <IconButton edge="start" sx={{ color: 'black', mb: 2 }}>
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
              Enter OTP sent to {userEmail}
            </Typography>
          </>
        )}

        <OTPInput
          value={otp}
          onChange={setOtp}
          numInputs={4}
          inputType='password'
          separator={
            <span>
              <strong>.</strong>
            </span>
          }
          renderInput={(props) => <input {...props} />}
          inputStyle={{
            width: "3rem",
            height: "3rem",
            marginLeft: '5px',
            marginRight: '5px',
            padding: '10px',
            fontSize: "1rem",
            borderRadius: 4,
            marginTop: '1rem',
            border: "1px solid rgba(0,0,0,0.3)"
          }}
        />
        <br />

        <Link
          href="#"
          onClick={timer > 0 ? null : handleResend}
          underline="hover"
          sx={{ display: 'block', mb: 3, color: 'white', fontWeight: 'bold' }}
          disabled={timer > 0}
        >
          {timer > 0 ? `Resend OTP in ${timer}s` : 'Resend OTP'}
        </Link>

        <ContinueButton disabled={isLoading} onClick={handleSubmit} fullWidth>
          Continue
        </ContinueButton>
        <Button disabled={isLoading} onClick={handleBack} fullWidth sx={{ mt: 2 }}>
          Back
        </Button>
      </Box>
    </>
  );
};

export default VerifyForm;
