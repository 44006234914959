import React from 'react';
import { AppBar, Toolbar, Container, Box, Button, Typography } from '@mui/material';
import XclusiveLogo from '../../../components/logo';
import useAuth from '../../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const { user, logout, isAuthenticated } = useAuth();
  const navigate = useNavigate();
  return (
    <header>
      <AppBar position="fixed" sx={{ background: 'transparent' }}>
        <Container>
          <Toolbar>
            <XclusiveLogo />
            <Box sx={{ flexGrow: 2 }} />
            {isAuthenticated && <Typography sx={{ mr: 2 }} variant="subtitle1" align="left">Welcome {user.name ?? user.email}!</Typography>}
            {isAuthenticated && <Button variant="contained" color="primary" onClick={() => {
              navigate(`/onboarding/${user.type}/login`);
              logout();
            }}>Logout</Button>}
          </Toolbar>
        </Container>
      </AppBar>
    </header>
  );
};

export default Header;