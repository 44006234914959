import React, { useEffect, useState } from 'react';

import { Container, Typography, CircularProgress } from '@mui/material';

import useAuth from '../../../../hooks/useAuth';
import { OrganizerService } from '../../../../services/organizer.service';
import OnboardingOrganizerPortfolioForm from './form';

export default function OnboardingOrganizerPortfolio() {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  useEffect(() => {
    const fetchOrganizer = async () => {
      const response = await OrganizerService.getOrganizerByUserId(user.id);
      const venues = await OrganizerService.getVenuesByOrganizerId(user.id);
      setInitialValues({ ...response, venues });
    };
    console.log(initialValues, 'initialValues');
    fetchOrganizer();
  }, []);

  return (
    <>
      <Container maxWidth="md" sx={{ mb: 5 }}>
        <Typography variant="h4" align="left">Hello {user.name}!</Typography>
        <Typography variant="subtitle1" align="left">Let's create your organizer portfolio.</Typography>
        {loading && <CircularProgress />}
        {!loading && <OnboardingOrganizerPortfolioForm  setInitialValues={setInitialValues} initialValues={initialValues} setLoading={setLoading} isLoading={loading} />}
      </Container>
    </>
  )
}