import { jwtDecode } from 'jwt-decode';

import { UserService } from '../services/user.service';

import axios from './axios.utils'

const isValidToken = (access) => {
  if (!access) {
    return false;
  }
  const decoded = jwtDecode(access);

  const currentTime = Date.now();

  return decoded.exp > currentTime;
};

const handleTokenExpired = (exp) => {
  let expiredTimer;

  const currentTime = Date.now();

  // Test token expires after 10s
  // const timeLeft = currentTime + 10000 - currentTime; // ~10s
  const timeLeft = exp - currentTime;

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(async () => {
    const refresh = localStorage.getItem('refresh');
    const isRenewed = await renewToken(refresh);
    if (isRenewed) return;
    localStorage.clear();
    // window.location.href = PATH_AUTH.login;
  }, timeLeft);
};

const renewToken = async (token) => {
  try {
    const { access, refresh } = await UserService.renewToken(token);
    setSession(access, refresh);
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

const setSession = (access, refresh) => {
  if (access && refresh) {
    localStorage.setItem('access', access);
    localStorage.setItem('refresh', refresh);
    axios.defaults.headers.common.Authorization = `${access}`;
    const { exp } = jwtDecode(access);
    handleTokenExpired(exp);
  } else {
    localStorage.removeItem('access');
    localStorage.removeItem('refresh');
    delete axios.defaults.headers.common.Authorization;
  }
};

export { isValidToken, setSession, renewToken };