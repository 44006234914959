import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

const LogoTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 'bold',
  padding: '0px',
  margin: '0px',
  fontSize: '1.5rem',
}));

const XclusiveLogo = () => {
  return (
    <Typography variant="h6">
      <LogoTitle component="span">
        X
      </LogoTitle>
      clusive
    </Typography>
  );
};

export default XclusiveLogo;