import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import AuthGuard from './auth.guard';

import OnboardingOrganizerPortfolio from '../pages/onboarding/organizer/portfolio';
import OnboardingLayout from '../layout/onboarding';
import OnboardingProfile from '../pages/onboarding/profile';
import OnboardingArtistsPortfolio from '../pages/onboarding/artists/porfolio';
import Onboarding from '../pages/onboarding';
import OnboardingArtists from '../pages/onboarding/artists';
import OnboardingOrganizer from '../pages/onboarding/organizer';

const AppRouter = () => {
  return useRoutes([
    {
      path: '/onboarding',
      element: <OnboardingLayout />,
      children: [
        {
          path: '/onboarding',
          index: true,
          element: (<AuthGuard><Onboarding /></AuthGuard>),
        },
        { path: '/onboarding/artist/login', element: <OnboardingArtists type="artist" /> },
        { path: '/onboarding/organizer/login', element: <OnboardingOrganizer type="organizer" /> },
        { path: '/onboarding/profile', element: (<AuthGuard><OnboardingProfile /></AuthGuard>) },
        { path: '/onboarding/artist/portfolio', element: (<AuthGuard><OnboardingArtistsPortfolio /></AuthGuard>) },
        { path: '/onboarding/organizer/portfolio', element: (<AuthGuard><OnboardingOrganizerPortfolio /></AuthGuard>) },
        { path: '*', element: <div>404 Not Found</div> },
      ],
    }
  ]);
};

export default AppRouter;
