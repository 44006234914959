// VenueComponent.js
import React, { useEffect, useState } from 'react';
import {
  Grid,
  TextField,
  IconButton,
  Box,
  Typography,
  Button,
} from '@mui/material';
import { Add, Delete } from '@mui/icons-material';

const VenueComponent = ({ formFields, setFieldsInForm }) => {
  const [fields, setFields] = useState(formFields ?? [{ name: '', area: '', city: '' }]);
  useEffect(() => {
    setFields(formFields ?? [{ name: '', area: '', city: '' }]);
  }, [formFields]);
  const handleAddField = () => {
    setFields([...fields, { name: '', area: '', city: '' }]);
  };
  const handleRemoveField = (index) => {
    if (fields.length > 1) {
      setFields(fields.filter((_, i) => i !== index));
    }
  };
  
  const handleChange = (index, field, value) => {
    const updatedFields = [...fields];
    updatedFields[index][field] = value;
    setFields(updatedFields);
    setFieldsInForm(updatedFields);
  };

  return (
    <>
      <Typography variant="h6" align="left" gutterBottom>
        Venues You've Worked At
      </Typography>
      <Typography variant="subtitle1" align="left" gutterBottom sx={{ mb: 2 }}>
        Add the venues you've worked at so we can show your portfolio to the right people.
      </Typography>
      {fields.map((field, index) => (
        <Box
          key={index}
          sx={{
            position: 'relative',
            mb: 2,
            '&:hover': { boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' },
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Name"
                value={field.name}
                onChange={(e) => handleChange(index, 'name', e.target.value)}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Area"
                value={field.area}
                onChange={(e) => handleChange(index, 'area', e.target.value)}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="City"
                value={field.city}
                onChange={(e) => handleChange(index, 'city', e.target.value)}
                fullWidth
                required
              />
            </Grid>
          </Grid>

          {/* Delete Button Only on Hover */}
          <IconButton
            color="error"
            onClick={() => handleRemoveField(index)}
            sx={{
              position: 'absolute',
              top: '8px',
              right: '8px',
              backgroundColor: 'rgba(0, 0, 0, 0.1)',
              '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.2)' },
              opacity: 0,
              transition: 'opacity 0.3s',
              '&:hover': { opacity: 1 },
            }}
          >
            <Delete />
          </IconButton>
        </Box>
      ))}
      <Box textAlign="right" sx={{ marginTop: '20px' }}>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<Add />}
          onClick={handleAddField}
        >
          Add More
        </Button>
      </Box>

    </>
  );
};

export default VenueComponent;
